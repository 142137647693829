<template>
  <div class="selectDownBox">
    <p class="selectLabel" v-if="label">{{ label }}</p>
    <el-select
      v-model="baseData.selectVal"
      placeholder="请选择"
      @change="selectDownHandle"
    >
      <el-option
        v-for="item in props.selectData"
        :key="item[itemValue]"
        :label="item[itemLabel]"
        :value="item[itemValue]"
      >
        <div class="option-slot" v-if="isSlot">
          <div class="option-slot-l" v-if="item['logo']">
            <img :src="item['logo']" />
          </div>
          <div class="option-slot-r">{{ item[itemLabel] }}</div>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script setup>
// eslint-disable-next-line
import { reactive, defineEmits, defineProps, onMounted, watch } from 'vue'

const baseData = reactive({
  selectVal: ''
})
const props = defineProps({
  label: {
    type: String,
    default: '下拉选择'
  },
  selectVal: {
    type: String,
    default: ''
  },
  selectData: {
    type: Array,
    default: () => []
  },
  itemLabel: {
    type: String,
    default: 'label'
  },
  itemValue: {
    type: String,
    default: 'value'
  },
  isSlot: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([])

// 下拉选择相关
const selectDownHandle = (val) => {
  emit('selectDownCall', val)
}

onMounted(() => {
  baseData.selectVal = props.selectVal
})
watch(() => props.selectVal, (newValue) => {
  baseData.selectVal = newValue
})
</script>

<style lang="scss" scoped>
.selectDownBox {
  display: flex;
  align-items: center;
  .selectLabel {
    color: #606266;
    margin: 0;
    padding-right: 12px;
    font-size: 14px;
    min-width: 80px;
    text-align: right;
  }
}
.option-slot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &-l {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px 8px 0;
    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      overflow: hidden;
    }
  }
  &-r {
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
