<template>
  <div class="selectTimesBox">
    <p class="selectLabel">{{ props.label }}</p>
    <el-date-picker
      v-model="baseData.selectTimes"
      type="daterange"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      :default-value="props.defaultTime"
      @change="selectTimesHandle"
      format="YYYY-MM-DD"
      value-format="YYYY-MM-DD"
    />
  </div>
</template>

<script setup>
// eslint-disable-next-line
import { reactive, defineEmits, defineProps, onMounted, watch } from 'vue'

const baseData = reactive({
  selectTimes: []
})
const props = defineProps({
  label: {
    type: String,
    default: '选择日期：'
  },
  defaultTime: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['selectTimesCall'])

// 选择时间相关
const selectTimesHandle = (val) => {
  emit('selectTimesCall', baseData.selectTimes)
}

onMounted(() => {
  baseData.selectTimes = props.defaultTime
})
watch(() => props.defaultTime, (newValue) => {
  baseData.selectTimes = newValue
})
</script>

<style lang="scss" scoped>
.selectTimesBox {
  display: flex;
  align-items: center;
  .selectLabel {
    color: #606266;
    margin: 0 6px 0 12px;
    font-size: 14px;
  }
}
</style>
