<template>
  <div class="storeBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          团队报表
        </div>
        <div class="card-hd-right">
        </div>
      </div>
    </div>

    <div class="handleLineBox">
      <!-- select -->
      <div class="baseSelect">
        <!-- 时间选择 -->
        <SelectTimes
          :defaultTime="[]"
          @selectTimesCall="selectTimesHandle"
        ></SelectTimes>
        <!-- 下拉选择 -->
        <SelectDown
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <!-- 弹窗 -->
        <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('goods')">新增商品</el-button>
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="SimpleDialogClose"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  新增商品
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabs.currentIndex"
                    @tab-click="tabsListHandle">
                    <el-tab-pane
                      v-for="item in tabs.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      @scroll="doScroll"
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormRef"
                        :model="ruleForm"
                        status-icon
                        :rules="rules"
                        label-width="80px"
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              补充信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem2">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              供应商信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem3">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              商品库存
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem4">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              仓位库容
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem5">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              其他成本
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem6">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              其他信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem7">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              商品状态
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                            <el-form-item label="品牌名称" prop="brand">
                              <el-input v-model="ruleForm.brand"
                                maxlength="30"
                                placeholder="请输入品牌名称"
                                show-word-limit
                              />
                            </el-form-item>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="submitForm(ruleFormRef)">提交</el-button>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>

    <div class="echart-demo">
      <div id="myChart1" class="echart-box"></div>
      <div id="myChart2" class="echart-box"></div>
      <div id="myChart3" class="echart-box"></div>
      <div id="myChart4" class="echart-box echart-absolute"></div>
    </div>

    <!-- table -->
    <div class="baseTable">
      <!-- <el-scrollbar> -->
      <el-table
        v-loading="baseData.tableLoading"
        :data="baseData.tableListData"
        style="width: 100%;">
        <el-table-column
          v-for="(item, index) in baseData.tableHdData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item?.minWidth"
          :fixed="item.prop == 'handle' ? 'right' : item.prop == 'name' ? 'left' : false"
          align="center"
        >
          <template #default="scope">
            <el-tag
              v-if="item.prop == 'platform'"
              type="danger"
              disable-transitions
            >
              {{ scope.row.platform }}
            </el-tag>
            <div v-else-if="item.prop == 'handle'">
              <el-button size="small" type="primary">Primary</el-button>
              <el-button size="small" type="success">Primary</el-button>
              <el-button size="small" type="danger">Primary</el-button>
            </div>
            <div v-else-if="item.prop == 'address'">
              {{ scope?.row[item.prop] ? scope.row[item.prop] : '未设置' }}
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <!-- table empty -->
        <template #empty>
          <div class="baseTableEmpty">
            <el-empty description="暂无店铺">
              <el-button type="primary" @click="addPlatformHandle">添加店铺</el-button>
            </el-empty>
          </div>
        </template>
      </el-table>
      <!-- </el-scrollbar> -->
    </div>

    <!-- pagination 分页 -->
    <div class="basePagination">
      <el-config-provider :locale="zhCn">
        <el-pagination
          v-model:current-page="baseData.currentPage"
          v-model:page-size="baseData.pageSize"
          :page-sizes="baseData.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="baseData.pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-config-provider>
    </div>

  </div>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// eslint-disable-next-line
import SelectTimes from '@/components/SelectTimes/index.vue'
import SelectDown from '@/components/SelectDown/index.vue'
import { nextTick, reactive, ref, watch, onMounted } from 'vue'
import { ElMessageBox } from 'element-plus'
import * as echarts from 'echarts'
import geoJson from '@/utils/echart/china.json'
echarts.registerMap('china', geoJson)

const baseData = reactive({
  selectTimes: '',
  selectVal: 'Option2',
  selectData: [
    {
      value: 'Option1',
      label: 'Option1'
    },
    {
      value: 'Option2',
      label: 'Option2'
    },
    {
      value: 'Option3',
      label: 'Option3'
    },
    {
      value: 'Option4',
      label: 'Option4'
    },
    {
      value: 'Option5',
      label: 'Option5'
    }
  ],
  tableLoading: false,
  tableHdData: [
    { label: '店铺', prop: 'name', minWidth: '200' },
    { label: '所属平台', prop: 'platform', minWidth: '80' },
    { label: '销售金额', prop: 'saleMoney', minWidth: '180' },
    { label: '实际销售金额', prop: 'saleMoneyTrue', minWidth: '180' },
    { label: '退款金额', prop: 'refundMoney', minWidth: '180' },
    { label: '销售订单数', prop: 'saleOrder', minWidth: '180' },
    { label: '退款订单数', prop: 'refundOrder', minWidth: '180' },
    { label: '操作', prop: 'handle', minWidth: '250' }
  ],
  tableListData1: [],
  tableListData: [
    {
      name: '平台店铺名称平台店铺名称',
      platform: '拼多多',
      saleMoney: 10000,
      saleMoneyTrue: 10000,
      refundMoney: 10000,
      saleOrder: 10000,
      refundOrder: 10000
    },
    {
      name: '平台店铺名称平台店铺名称',
      platform: '拼多多',
      saleMoney: 10000,
      saleMoneyTrue: 10000,
      refundMoney: 10000,
      saleOrder: 10000,
      refundOrder: 10000
    },
    {
      name: '平台店铺名称平台店铺名称',
      platform: '拼多多',
      saleMoney: 10000,
      saleMoneyTrue: 10000,
      refundMoney: 10000,
      saleOrder: 10000,
      refundOrder: 10000
    }
  ],
  currentPage: '1',
  pageSize: 100,
  pageTotal: 0,
  pageSizes: [100, 200, 300, 400],
  SimpleDialogShow: false,
  scrollSite: 0
})

const goodsFormScoll = ref()
// tabs信息
const tabs = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: '补充信息', name: 1 },
    { title: '供应商信息', name: 2 },
    { title: '商品库存', name: 3 },
    { title: '仓位库容', name: 4 },
    { title: '其他成本', name: 5 },
    { title: '其他信息', name: 6 },
    { title: '商品状态', name: 7 }
  ]
})
// elementPlus滚动条缓动
const scrollToTopTransition = (willGo, nowSite = 0, duration = 300) => {
  let passedTime = 0
  const originTime = Date.now()
  // 贝塞尔缓动式由0到1
  const easeingFunction = (t) => {
    if (t < 0.5) {
      return (4 * t * t * t)
    } else {
      return ((t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
    }
  }
  const _scrollToTop = () => {
    if (passedTime < duration) {
      passedTime = Date.now() - originTime
      // duration(默认300毫秒)内重复执行
      requestAnimationFrame(_scrollToTop)
      // 当前位置nowSite到预期位置willGo的真实滚动距离
      const trueDistance = (nowSite + (willGo - nowSite) * (easeingFunction(passedTime / duration)))
      goodsFormScoll.value.setScrollTop(trueDistance)
    }
  }
  _scrollToTop()
}
// tabs点击事件
const tabsListHandle = (e) => {
  const indexNum = Number(e.index)
  const willGoCard = document.querySelector(`#cardItem${indexNum}`)
  willGoCard.classList.add('willFocus')
  setTimeout(() => {
    willGoCard.classList.remove('willFocus')
  }, 2000)
  scrollToTopTransition(tabs.list[indexNum].offsetTop - 12, baseData.scrollSite)
}
// tabs 监听滚动
const doScroll = (event) => {
  const list = tabs.list
  baseData.scrollSite = event.scrollTop
  for (const i in list) {
    console.log(tabs.list[i].offsetTop, '--', baseData.scrollSite)
    const { offsetTop, name } = tabs.list[i]
    if (offsetTop >= baseData.scrollSite) {
      tabs.currentIndex = name
      break
    }
  }
}
// 获取cradItem锚点距顶坐标
const getCardItemSite = () => {
  nextTick(() => {
    const list = tabs.list
    for (const i in list) {
      tabs.list[i].offsetTop = document.querySelector(`#cardItem${i}`).offsetTop
    }
  })
}
// tabs currentIndex监听
watch(() => tabs.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite()
})

// 表单 信息
const ruleForm = reactive({
  brand: ''
})
// 表单 验证相关
const ruleFormRef = ref()
// 表单 单独校验规则
const checkBrand = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入品牌名称'))
  }
  setTimeout(() => {
    if (value.length > 30) {
      callback(new Error('品牌名不能超过30个字'))
    } else {
      callback()
    }
  }, 1000)
}
// 表单 校验规则
const rules = reactive({
  brand: [{ required: true, validator: checkBrand, trigger: 'blur' }]
})
// 表单 验证后提交表单
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!')
    } else {
      console.log('不能提交')
      return false
    }
  })
}
// 表单 重置表单
const resetForm = (formEl) => {
  if (!formEl) return
  ElMessageBox.confirm(
    '重置后所有未保存的内容均会丢失，是否继续？',
    {
      confirmButtonText: '重置',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    formEl.resetFields()
  }).catch(() => {
  })
}
// simpleDialog 弹窗唤醒事件
const SimpleDialogHandle = (type) => {
  baseData.SimpleDialogShow = true
}
// simpleDialog 关闭事件
const SimpleDialogClose = () => {
  baseData.SimpleDialogShow = false
}

// 分页相关
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
// 下拉选择相关
const selectDownHandle = (val) => {
  baseData.selectVal = val
}

// 选择时间相关
const selectTimesHandle = (val) => {
  baseData.selectTimes = val
}

onMounted(() => {
  const myChart1 = echarts.init(document.getElementById('myChart1'))
  const myChart2 = echarts.init(document.getElementById('myChart2'))
  const myChart3 = echarts.init(document.getElementById('myChart3'))
  const myChart4 = echarts.init(document.getElementById('myChart4'))

  const areaStack = {
    color: [
      '#e66161', '#d161e6', '#6188e6', '#61dce6', '#6f61e6', '#e6b061'
    ],
    title: {
      text: '名称名称名称'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      data: ['店铺1', '店铺2', '店铺3', '店铺4', '店铺5']
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周天']
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: '店铺1',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [120, 132, 101, 134, 90, 230, 210]
      },
      {
        name: '店铺2',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [220, 182, 191, 234, 290, 330, 310]
      },
      {
        name: '店铺3',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [150, 232, 201, 154, 190, 330, 410]
      },
      {
        name: '店铺4',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [320, 332, 301, 334, 390, 330, 320]
      },
      {
        name: '店铺5',
        type: 'line',
        stack: 'Total',
        label: {
          show: true,
          position: 'top'
        },
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [820, 932, 901, 934, 1290, 1330, 1320]
      }
    ]
  }
  const borderRadius = {
    color: [
      '#e66161', '#d161e6', '#6188e6', '#61dce6', '#6f61e6', '#e6b061'
    ],
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 1048, name: '店铺1' },
          { value: 735, name: '店铺2' },
          { value: 580, name: '店铺3' },
          { value: 484, name: '店铺4' },
          { value: 300, name: '店铺5' }
        ]
      }
    ]
  }
  const barTick = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周天'],
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'bar',
        barWidth: '60%',
        data: [
          {
            value: 120,
            itemStyle: {
              color: '#e66161',
              borderRadius: [10, 10, 10, 10]
            }
          },
          {
            value: 200,
            itemStyle: {
              color: '#d161e6',
              borderRadius: [10, 10, 10, 10]
            }
          },
          {
            value: 150,
            itemStyle: {
              color: '#6188e6',
              borderRadius: [10, 10, 10, 10]
            }
          },
          {
            value: 80,
            itemStyle: {
              color: '#61dce6',
              borderRadius: [10, 10, 10, 10]
            }
          },
          {
            value: 70,
            itemStyle: {
              color: '#e6b061',
              borderRadius: [10, 10, 10, 10]
            }
          },
          {
            value: 110,
            itemStyle: {
              color: '#6f61e6',
              borderRadius: [10, 10, 10, 10]
            }
          },
          {
            value: 130,
            itemStyle: {
              color: '#e66161',
              borderRadius: [10, 10, 10, 10]
            }
          }
        ]
      }
    ]
  }
  const chinaMap = {
    // 进行相关配置
    title: {
      text: '地区销量分析'
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        saveAsImage: { show: true },
        dataView: { show: true, readyOnly: true },
        magicType: { show: true, type: ['line', 'bar', 'stack'] },
        restore: { show: true },
        savesImage: { show: true }
      }
    },
    tooltip: {}, // 鼠标移到图里面的浮动提示框
    visualMap: {
      min: 0,
      max: 1000,
      text: ['High', 'Low'],
      realtime: false,
      calculable: true,
      inRange: {
        color: ['lightskyblue', 'yellow', 'orangered']
      }
    },
    geo: {
      // 这个是重点配置区
      map: 'china', // 表示中国地图
      roam: true,
      label: {
        normal: {
          show: true, // 是否显示对应地名
          textStyle: {
            color: '#333333'
          }
        }
      },
      itemStyle: {
        normal: {
          borderColor: '#6F61E6',
          borderWidth: '1.1',
          areaColor: '#f6f5ff'
        },
        emphasis: {
          areaColor: null,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 20,
          borderWidth: 0,
          shadowColor: '#6F61E6'
        }
      }
    },
    series: [
      {
        type: 'scatter',
        coordinateSystem: 'geo' // 对应上方配置
      },
      {
        name: '地区销量', // 浮动框的标题
        type: 'map',
        geoIndex: 0,
        data: [
          {
            name: '北京',
            value: 599
          },
          {
            name: '上海',
            value: 142
          },
          {
            name: '黑龙江',
            value: 44
          },
          {
            name: '广东',
            value: 92
          },
          {
            name: '湖北',
            value: 810
          },
          {
            name: '四川',
            value: 453
          },
          {
            name: '新疆',
            value: 666
          }
        ]
      }
    ],
    legend: {}
  }
  myChart1.setOption(areaStack)
  myChart2.setOption(borderRadius)
  myChart3.setOption(barTick)
  myChart4.setOption(chinaMap)
  window.onresize = function () { // 自适应大小
    myChart1.resize()
    myChart2.resize()
    myChart3.resize()
    myChart4.resize()
  }
})

</script>

<style lang="scss" >
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';

.detailFormDialogBox {
  @include dialogBoxBase;
  .el-dialog {
    height: calc( 100% - 80px);
  }
  .card-box {
    .card-bd {
      padding-top: 24px;
    }
  }
}
.goodsForm {
  @include formBoxBase;
  height: calc( 100% - 100px);
}

.echart-demo {
  display: flex;
  flex-wrap: wrap;
  .echart-box {
    width: 50%;
    height: 250px;
  }
  .echart-absolute {
    position: absolute;
    width: 100%;
    height: 800px;
    right: 0;
    z-index: 99;
    background: #0000003d;
  }
}
</style>
